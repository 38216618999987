import { DarkMode, Stack, Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Icon } from "@chakra-ui/react"
import { HiPlus } from "react-icons/hi"
import Link from "next/link"

const data = [
    {
        title: 'Is Rarchy really free?',
        answer: (
            <Stack spacing={4}>
                <Box>
                    Yes! Rarchy’s free plan is one of the only tools on the market to offer a free visual sitemap generator combined with a free website crawler.* Free forever, no credit card required.
                </Box>
                <Box>
                    To expand feature limits and unlock premium features, upgrade to our Rarchy Pro or Rarchy Enterprise plans.
                </Box>
                <Box>
                    <Box display="inline" textDecor="underline" textUnderlineOffset={6} textDecorationColor="whiteAlpha.500"><Link href="/pricing">Visit our pricing page</Link></Box> for more information on premium plans.
                </Box>
                <Box fontSize="sm">
                    ‍* Crawl up to 50 pages for free.
                </Box>
            </Stack>
        ),
    },
    {
        title: 'What else can I do with Rarchy?',
        answer: (
            <Stack spacing={4}>
                <Box>
                    Rarchy is much more than a crawler. You can use Rarchy to design user journeys, capture screenshots to create an archive of your pages, map out information architecture, plan new content, visualize website migrations, and much more.
                </Box>
                <Box>
                    Collaboration features such as comments and folders with permissions make it easy to work on website projects with your internal team and external clients.
                </Box>
            </Stack>
        ),
    },
    {
        title: 'What happens to my existing projects if I upgrade?',
        answer: "Don't worry, you won't lose any of your work. All existing projects will be copied to your new organization account if you upgrade."
    },
]

export const FooterFAQS = () => {
    return (
        <DarkMode>
            <Accordion allowMultiple minW={{ base: '18.75rem', lg: '31.25rem' }}>
                {data.map((item, index) => (
                    <AccordionItem key={index} borderColor="rarchy-border-accordion">
                        {({ isExpanded }) => (
                            <>
                                <h2>
                                    <AccordionButton >
                                        <Box
                                            as='span'
                                            flex='1'
                                            paddingY='4'
                                            textAlign='left'
                                            textStyle='body'
                                            fontWeight='semibold'
                                            color="fg.default"
                                        >
                                            {item.title}
                                        </Box>
                                        <Icon
                                            as={HiPlus}
                                            color="fg.default"
                                            transition='transform 0.4s'
                                            transform={isExpanded ? 'rotate(45deg)' : undefined}
                                        />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel paddingBottom='8' color="fg.default">
                                    {item.answer}
                                </AccordionPanel>
                            </>
                        )}
                    </AccordionItem>
                ))}
            </Accordion>
        </DarkMode>
    )
}