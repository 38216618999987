/* eslint-disable react-hooks/rules-of-hooks */

import { useGetAdditionalSeatPricing, useGetNumberOfSeatsIncludedInPlan, useGetPagesImportLimitByPlan, useGetPlanLimits, useGetPriceWithCurrencySymbol, useShowAnnualBilling, useShowCurrencyForDollarSymbol } from "./hooks/pricing";
import { useGetAnnualPlanPrice } from "./hooks";
import { Stack, Text } from "@chakra-ui/react";

export const plans = (pricing) => {
    const planId = (id) => pricing?.plans?.[id]?.id
    const seats = (id) => useGetNumberOfSeatsIncludedInPlan({ planId: id })
    return [
        {
            id: 'free',
            name: 'Free',
            description: 'Get started on your website project with basic features and generous free limits.',
            pricing: pricing?.plans?.['free'],
        },
        {
            id: 'pro',
            name: 'Professional',
            stripeId: planId('pro'),
            description: 'Unlock premium features with increased limits. Best for small websites.',
            pricing: pricing?.plans?.['pro'],
        },
        {
            id: 'enterprise',
            name: 'Enterprise',
            stripeId: planId('enterprise'),
            seats: seats('enterprise'),
            description: 'Collaborate with more of your team with increased limits. Best for larger websites.',
            pricing: pricing?.plans?.['enterprise']
        }
    ]
}

export const plansTableRows = () => {
    const showAnnualBilling = useShowAnnualBilling()
    const pages_per_import = (id) => useGetPagesImportLimitByPlan({ planId: id })
    const screenshots = (id) => useGetPlanLimits(id)?.screenshots
    const seats = (id) => useGetNumberOfSeatsIncludedInPlan({ planId: id })
    const additional_seats_pricing = useGetPriceWithCurrencySymbol(showAnnualBilling ? Math.round((useGetAnnualPlanPrice(useGetAdditionalSeatPricing()) / 12) * 100) / 100 : useGetAdditionalSeatPricing())
    const currencyForDollarSymbol = useShowCurrencyForDollarSymbol()
    return [
        {
            group: 'Collaboration',
            rows: [
                {
                    label: 'Seats',
                    description: 'The number of people included in your account.',
                    data: [seats('free'), seats('pro'), seats('enterprise')]
                },
                {
                    label: 'Additional seats',
                    data: [false, true, true],
                    description: `Add as many people as you need to your account, for ${additional_seats_pricing} ${currencyForDollarSymbol || ''} / user / month.`,
                },
                {
                    label: 'Shared workspace',
                    data: [false, { asterix: true }, true],
                    description: (
                        <Stack>
                            <Text>
                                Stay aligned and avoid duplicating work by bringing everyone under one Rarchy account.
                            </Text>
                            <Text>
                                * with additional users
                            </Text>
                        </Stack>
                    ),
                },
                {
                    label: 'Role-based access control',
                    data: [false, { asterix: true }, true],
                    description: (
                        <Stack>
                            <Text>
                                Keep control of your account by assigning admin/user roles to your team members.
                            </Text>
                            <Text>
                                * with additional users
                            </Text>
                        </Stack>
                    ),
                },
                {
                    label: 'Folders with permissions',
                    data: [false, { asterix: true }, true],
                    description: (
                        <Stack>
                            <Text>
                                Keep your projects organized with public/private folders.
                            </Text>
                            <Text>
                                * with additional users
                            </Text>
                        </Stack>
                    ),
                },
                {
                    label: 'Projects:',
                    subheader: true,
                    data: ["", "", ""]
                },
                {
                    label: 'Comments',
                    indent: true,
                    data: [true, true, true],
                    description: 'Gather project feedback and tag your collaborators to keep them informed of future plans.'
                },
                {
                    label: 'Share with view-only access',
                    indent: true,
                    data: [true, true, true],
                    description: 'Anyone with a project link can view, no need for sign-in.'
                },
                {
                    label: 'Share with edit/comment access',
                    indent: true,
                    data: [false, true, true],
                    description: 'Invite unlimited collaborators to comment on/edit your projects.'
                },
                {
                    label: 'Version history',
                    indent: true,
                    data: [false, true, true],
                    description: 'View a full timeline of all changes made to your projects, by all users. Restore changes in one click.'
                },
            ],
        },
        {
            group: 'Sitemaps',
            rows: [
                {
                    label: 'Number of sitemaps',
                    data: [1, "Unlimited", "Unlimited"],
                    description: 'The number of "active" sitemaps you can have in your account.'
                },
                {
                    label: 'Pages per sitemap',
                    data: [pages_per_import('free'), "Unlimited", "Unlimited"],
                    description: 'The maximum amount of pages you can have within a sitemap.'
                },
                {
                    label: 'Sitemap templates',
                    data: [true, true, true],
                    description: 'Use one of our handy sitemap templates to start planning your pages.'
                },
                {
                    label: 'Export (SVG, PDF, XML, CSV)',
                    data: [true, true, true],
                    description: 'Export your projects in all formats, as many times as you need.'
                },
                {
                    label: 'Visual Sitemap Generator:',
                    subheader: true,
                    data: ["", "", ""]
                },
                {
                    label: 'Pages per import',
                    indent: true,
                    data: [pages_per_import('free'), pages_per_import('pro'), pages_per_import('enterprise')],
                    description: 'Import as many times as you need, with a max limit of the number of pages per import'
                },
                {
                    label: 'Website crawler',
                    indent: true,
                    data: [true, true, true],
                    description: 'Generate your sitemaps from a fresh crawl of your website.'
                },
                {
                    label: 'Extract SEO Meta tags',
                    indent: true,
                    data: [true, true, true],
                    description: "We'll automatically extract SEO Meta Tags from your website pages for you."
                },
                {
                    label: 'Pages:',
                    subheader: true,
                    data: ["", "", ""]
                },
                {
                    label: 'Sitemap editor',
                    indent: true,
                    data: [true, true, true],
                    description: 'Access to our real-time, collaborative, drag and drop sitemap editor'
                },
                {
                    label: 'Wireframe templates',
                    indent: true,
                    data: [true, true, true],
                    description: 'Choose from over 250+ low-fidelity wireframe templates, handily grouped into 12 categories for searching.'
                },
                {
                    label: 'SEO Meta Tags',
                    indent: true,
                    data: [true, true, true],
                    description: 'Create, store, and export your SEO Meta Tags on a per-page basis in our sitemap editor'
                },
                {
                    label: 'Page section blocks',
                    indent: true,
                    data: ["3", "Unlimited", "Unlimited"],
                    description: 'Plan your page content by adding individual page sections within your visual sitemap.'
                },
                {
                    label: 'Custom page colors',
                    indent: true,
                    data: [false, true, true],
                    description: 'Organize your pages using custom colors and intuitive labelling.'
                },
                {
                    label: 'Custom image covers',
                    indent: true,
                    data: [false, true, true],
                    description: 'Upload your own images to display as a page cover.'
                },
                {
                    label: 'File uploads',
                    indent: true,
                    data: [false, true, true],
                    description: 'Attach files to any page, for safe storage and collaboration.'
                },
                {
                    label: 'Screenshots:',
                    subheader: true,
                    data: ["", "", ""]
                },
                {
                    label: 'Screenshots per month',
                    indent: true,
                    data: [screenshots('free'), screenshots('pro'), screenshots('enterprise')],
                    description: 'The number of screenshots that you can capture every month.'
                },
                {
                    label: 'Full-page screenshots',
                    indent: true,
                    data: [false, true, true],
                    description: 'Capture full-page screenshots of your pages, perfect for archiving and content gathering'
                },
                {
                    label: 'Hide page elements',
                    indent: true,
                    data: [false, true, true],
                    description: 'Hide elements such as cookie banners, welcome popups and other modals.'
                }
            ],
        },
        {
            group: 'User Flows',
            rows: [
                {
                    label: 'Number of user flows',
                    data: [1, "Unlimited", "Unlimited"],
                    description: 'The number of "active" user flows you can have in your account.'
                },
                {
                    label: 'User flow steps',
                    data: [pages_per_import('free'), "Unlimited", "Unlimited"],
                    description: 'The maximum number of "steps" you can build within one user flow'
                },
                {
                    label: 'Export (SVG, PDF)',
                    data: [true, true, true],
                    description: 'Export your projects in all formats, as many times as you need.'
                },
                {
                    label: 'Link to sitemap pages',
                    data: [true, true, true],
                    description: 'Edits made to sitemap pages are automatically reflected in user flows, and vice versa.'
                },
            ],
        },
        {
            group: 'Administration',
            rows: [
                {
                    label: 'Archive projects',
                    data: [false, true, true],
                    description: 'Archive projects and restore in one click, up to 30 days later.'
                },
                {
                    label: 'Duplicate projects',
                    data: [false, true, true],
                    description: 'Duplicate any of your projects in one click, within our app.'
                },
                {
                    label: 'Daily backups',
                    data: [true, true, true],
                    description: 'We backup your projects every day, so you can rest easy.'
                },
                {
                    label: 'SSL security',
                    data: [true, true, true],
                    description: 'We use SSL to authenticate our identity and enable an encrypted connection'
                },
                {
                    label: 'Customer support',
                    data: ['Basic support', 'Premium support', 'Priority technical support'],
                    description: "We're here to help you get the most out of Rarchy, every step of the way."
                }
            ],
        }
    ]
}