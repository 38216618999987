import { Box, Button, Link as ChakraLink, DarkMode, Stack } from "@chakra-ui/react"
import { TRIAL_DAYS, useCanStartNewTrial } from "../../../../shared/hooks/pricing"

import { Headline } from "../../-components/Headline"
import Link from "next/link"

export const JoinBanner = ({ bgColor }) => {
    const canStartNewTrial = useCanStartNewTrial()
    return (
        <Box mx="auto" align="center" bgColor={bgColor || "white"} p={10} py={28}>
            <DarkMode>
                <Stack align="center" spacing={10}>
                    <Headline fontWeight="normal" maxW="xl" size={{ base: "md", md: "lg" }} heading="Join the thousands of companies that use Rarchy" textAlign="center" />
                    <Stack spacing={6}>
                        <ChakraLink
                            as={Link}
                            href={canStartNewTrial ? "/app/upgrade-modal?planId=pro" : "/pricing"}
                            color="white"
                            fontSize="lg"
                            fontWeight="semibold"
                            textDecor="underline"
                            _hover={{ transition: "none" }}
                        >
                            {canStartNewTrial ? `Start a free ${TRIAL_DAYS} day trial` : "Choose your plan"}
                        </ChakraLink>
                        <Link href="/app/signup?footer=1">
                            <Button size="lg" px={6} colorScheme="gray">Start for free</Button>
                        </Link>
                    </Stack>
                </Stack>
            </DarkMode>
        </Box>
    )
}